import { animated, useSpring } from '@react-spring/web'
import React, { useMemo } from 'react'

import { useRecoilState } from 'recoil'
import { exerciseState } from '../state/exerciseState'
import AirplaneIcon from './AirplaneIcon'
import styles from './Airplanes.module.css'

interface AirplanesProps {}

const useAnimation = (active: boolean) => {
    const leftOffset =
        document.body.clientWidth <= 1024 ? document.body.clientWidth - 80 : 312

    const [{ top, left }] = useSpring(
        {
            from: {
                top: -100,
                left: leftOffset,
            }, // Starting value of the animated property
            to: {
                top: document.body.clientHeight + 100,
                left: leftOffset,
            }, // Ending value of the animated property based on the state
            config: { duration: 90000 }, // Configuration for the animation, specifying the duration
            loop: true,
        },
        []
    )

    return useMemo(
        () =>
            !active ? (
                <animated.div
                    className={styles.plane}
                    style={{
                        position: 'absolute',
                        // rotate: '-90deg',
                        top,
                        left,
                    }}
                >
                    <AirplaneIcon />
                </animated.div>
            ) : null,
        [active, left, top]
    )
}

const Airplanes: React.FC<AirplanesProps> = () => {
    const [exercise] = useRecoilState(exerciseState)

    const animation = useAnimation(exercise?.active)

    return animation
}

export default Airplanes
