import { useCallback, useState } from 'react';
import { useFetchApiResource } from './auth';
import { useRecoilState } from 'recoil';

import { historyState, History } from '../state/historyState';

export const useSyncedHistory = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [history, setHistory] = useRecoilState(historyState);

    const syncHistory = useFetchApiResource<History>(
        '/history',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(history.exercises),
        },
        (history) => {
            setLoading(false);
            if (history)
                setHistory({ exercises: history.exercises, synced: true });
        },
        setError
    );

    const sync = useCallback(() => {
        setLoading(true);
        syncHistory();
        setLoading(false);
    }, [syncHistory]);

    return { loading, error, sync };
};
