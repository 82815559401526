import React, { useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { login } from '../requests/auth';
import { authState } from '../state/authState';
import styles from './Login.module.css';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const [auth, setAuth] = useRecoilState(authState);

    const setTokenCallback = useCallback(
        (event: MessageEvent<any>) => {
            if (event.data.type === 'NEW_TOKEN') {
                console.debug('Succesfully setup token in service worker');
                setAuth({ ...auth, loggedIn: true });
                navigate('/');
            }
        },
        [auth, navigate, setAuth]
    );

    useEffect(() => {
        navigator.serviceWorker.ready.then((registration) => {
            if (registration.active) {
                console.debug('Listening for messages from service worker');
                navigator.serviceWorker.onmessage = setTokenCallback;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigator.serviceWorker.ready]);

    useEffect(() => {
        if (auth?.loggedIn) {
            navigate('/');
        }
    }, [auth.loggedIn, navigate]);

    return (
        <div className={styles.login}>
            <form
                className={styles.loginForm}
                onSubmit={(e) => {
                    e.preventDefault();
                    try {
                        // Get values from form
                        const form = e.target as unknown as { value: string }[];
                        const email = form[0].value;
                        const password = form[1].value;
                        // Send to server
                        login(email, password).then(async (res) => {
                            // Handle success
                            if (res.status !== 200) {
                                const body = await res.json();
                                alert('Login failed: ' + body.error);
                            } else {
                                const body = await res.json();
                                const serviceWorker = navigator.serviceWorker;
                                const registration = await serviceWorker?.ready;
                                if (registration?.active) {
                                    navigator.serviceWorker.onmessage =
                                        setTokenCallback;
                                    registration?.active.postMessage({
                                        type: 'SET_TOKEN',
                                        token: body.token,
                                    });
                                    setAuth({ ...auth, email });
                                }
                            }
                        });
                    } catch (e: any) {
                        // Handle error
                        alert('Login failed:' + e.message);
                    }
                }}
            >
                <input type="email" placeholder="Email" autoComplete="email" />
                <input
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                />
                <div className={styles.loginButtons}>
                    <Link to={'/register'}>Register</Link>
                    <button type="submit">Login</button>
                </div>
            </form>
        </div>
    );
};

export default Login;
