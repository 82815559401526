import React from 'react'
import airplane from '../assets/airplane.png'

interface AirplaneIconProps {}

const AirplaneIcon: React.FC<AirplaneIconProps> = () => {
    return <img src={airplane} alt="airplane" />
}

export default AirplaneIcon
