import classNames from "classnames";
import React, { useState } from "react";
import { useRecoilState } from "recoil";

import Exercise from "./Exercise";
import styles from "./PausingExercise.module.css";
import Clock from "./Clock";
import starSky from "../../assets/star-sky.png";

import { exerciseState, ExerciseType } from "../../state/exerciseState";
import { useIsDarkMode } from "../../hooks";

interface PausingExerciseProps {}

const PausingExercise: React.FC<PausingExerciseProps> = () => {
    const isDarkMode = useIsDarkMode();
    const [exercise] = useRecoilState(exerciseState);
    const [startTime, setStartTime] = useState<number | undefined>();

    return (
        <Exercise
            title="Pause"
            type={ExerciseType.pausing}
            countdownCallback={() => {
                setStartTime(new Date().getTime());
            }}
            onFinish={() => {
                setStartTime(undefined);
                if (startTime) {
                    return new Date().getTime() - startTime;
                } else {
                    return 0;
                }
            }}
            animation={
                <div
                    className={classNames(styles.animation, {
                        [styles.active]: exercise?.active,
                    })}
                    style={{
                        ...(isDarkMode
                            ? {
                                  background: `url(${starSky})`,
                                  backgroundColor: "black",
                                  backgroundSize: "contain",
                              }
                            : {}),
                        animation: styles.pausing,
                        animationDuration: "10s",
                        animationDelay: "5s",
                        animationFillMode: "forwards",
                    }}
                >
                    <Clock startTime={startTime} />
                </div>
            }
        />
    );
};

export default PausingExercise;
