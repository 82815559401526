import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Exercise.module.css';
import { useRecoilState } from 'recoil';
import { exerciseState, ExerciseType } from '../../state/exerciseState';
import { trackerState } from '../../state/trackerState';
import { historyState } from '../../state/historyState';

interface ExerciseProps {
    title: string;
    type: ExerciseType;
    animation: JSX.Element;
    onFinish: () => number;
    onStart?: () => void;
    countdownCallback(): void;
}

const Exercise: React.FC<ExerciseProps> = ({
    animation,
    type,
    title,
    onStart,
    onFinish,
    countdownCallback,
}) => {
    const [tracker, setTracker] = useRecoilState(trackerState);
    const [history, setHistory] = useRecoilState(historyState);
    const [exercise, setExercise] = useRecoilState(exerciseState);
    const [countdown, setCountdown] = useState<number>(0);
    const [countdownTimer, setCountdownTimer] = useState<
        ReturnType<typeof setTimeout> | undefined
    >();

    const isActive = useMemo(() => {
        return (
            exercise?.active && exercise?.type === type && !exercise.finished
        );
    }, [exercise, type]);

    useEffect(() => {
        if (isActive) {
            if (exercise?.countdown && countdown < 5) {
                setCountdownTimer(
                    setTimeout(() => {
                        setCountdown(countdown + 1);
                    }, 1000)
                );
            } else {
                setTimeout(() => {
                    clearTimeout(countdownTimer);
                    setExercise({ ...exercise, countdown: false });
                    countdownCallback();
                }, 1000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exercise?.countdown, countdown]);

    const buttonText = useMemo(
        () => (!exercise?.active ? title : 5 - countdown),
        [exercise?.active, title, countdown]
    );

    const clickCallback = useCallback(() => {
        if (!isActive) {
            setExercise({
                type,
                active: true,
                countdown: true,
                finished: false,
            });
            clearTimeout(countdownTimer);
            setCountdown(0);
            if (onStart) onStart();
        } else if (isActive) {
            clearTimeout(countdownTimer);
            setCountdown(0);
            const result = onFinish();
            setExercise({
                type,
                active: false,
                countdown: false,
                finished: true,
                result,
            });
            const newTotal = tracker[type] + result;
            setTracker({
                ...tracker,
                active: true,
                [type]: newTotal,
            });
            if (result > 0) {
                setHistory({
                    exercises: [
                        ...history.exercises,
                        {
                            type: exercise.type,
                            result: result,
                            completed: new Date().getTime(),
                        },
                    ],
                    synced: false,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, onFinish, onStart]);

    if (exercise?.active && !isActive) {
        return null;
    }

    return (
        <div className={styles.exercise}>
            <div
                className={classNames(styles.indicatorContainer, {
                    [styles.active]: isActive,
                })}
                style={
                    isActive
                        ? {
                              width: document.body.clientWidth,
                              height: document.body.clientHeight,
                              borderRadius: 'initial',
                          }
                        : {}
                }
                onClick={clickCallback}
            >
                <div
                    className={classNames(styles.indicator, {
                        [styles.active]: exercise?.active,
                        [styles.inactive]: exercise?.finished,
                    })}
                >
                    <div
                        className={classNames(styles.indicatorText, {
                            [styles.active]: exercise?.active,
                            [styles.countdownFinished]:
                                exercise?.active && countdown >= 4,
                        })}
                    >
                        {buttonText}
                    </div>
                </div>
                {isActive ? animation : null}
            </div>
        </div>
    );
};

export default Exercise;
