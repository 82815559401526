import { atom } from 'recoil';
import { localStorageEffect } from './effects';

export interface Auth {
    loggedIn: boolean;
    email: string | undefined;
}

export const authState = atom<Auth>({
    key: 'authState', // unique ID (with respect to other atoms/selectors)
    default: { loggedIn: false, email: undefined },
    effects: [localStorageEffect('authState')],
});
