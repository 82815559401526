import classNames from 'classnames';
import React from 'react';
import { useRecoilState } from 'recoil';

import { formatTime } from './MessageDisplay';
import styles from './Tracker.module.css';

import { ExerciseType } from '../state/exerciseState';
import { trackerState } from '../state/trackerState';

interface TrackerProps {}

const PauseIcon = () => {
    return (
        <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0ZM27 20C27 17.7909 25.2091 16 23 16C20.7909 16 19 17.7909 19 20V43.5C19 45.7091 20.7909 47.5 23 47.5C25.2091 47.5 27 45.7091 27 43.5V20ZM45.5 20C45.5 17.7909 43.7091 16 41.5 16C39.2909 16 37.5 17.7909 37.5 20V43.5C37.5 45.7091 39.2909 47.5 41.5 47.5C43.7091 47.5 45.5 45.7091 45.5 43.5V20Z"
                fill="#808080"
            />
        </svg>
    );
};

const Tracker: React.FC<TrackerProps> = () => {
    const [tracker] = useRecoilState(trackerState);
    const { active, ...scores } = tracker;

    const breathing = scores[ExerciseType.breathing];
    const pausing = scores[ExerciseType.pausing];
    const focusing = scores[ExerciseType.focusing];

    return (
        <div
            className={classNames(styles.tracker, {
                [styles.active]: active,
            })}
        >
            {breathing ? (
                <div className={styles.score}>
                    {`${breathing} ${breathing > 1 ? 'Breaths' : 'Breath'}`}
                </div>
            ) : null}
            {pausing ? (
                <div className={styles.score}>
                    <PauseIcon />
                    {formatTime(pausing)}
                </div>
            ) : null}
            {focusing ? (
                <div className={styles.score}>{formatTime(focusing)}</div>
            ) : null}
        </div>
    );
};

export default Tracker;
