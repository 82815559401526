import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import {
    createBrowserRouter,
    Link,
    Outlet,
    RouterProvider,
    useLocation,
} from 'react-router-dom';
import { RecoilRoot, useRecoilState } from 'recoil';

import styles from './App.module.css';
import starSky from './assets/star-sky.png';
import Airplanes from './components/Airplanes';
import BreathingExercise from './components/exercises/BreathingExercise';
import PausingExercise from './components/exercises/PausingExercise';
import History from './components/History';
import Result from './components/MessageDisplay';
import Tracker from './components/Tracker';
import { useIsDarkMode, useIsMobile } from './hooks';
import Logo from './Logo';
import Login from './pages/Login';
import Register from './pages/Register';
import { useProfile } from './requests/profile';
import { authState } from './state/authState';
import { exerciseState } from './state/exerciseState';
import { useSyncedHistory } from './requests/sync';
import { AnimationControls, motion, useAnimation } from 'framer-motion';

const Page: React.FC<{
    children: JSX.Element[];
    logo: JSX.Element;
    onMove: () => void;
    lightsOff: boolean;
}> = ({ children, logo, onMove, lightsOff }) => {
    const isDarkMode = useIsDarkMode();
    const [exercise] = useRecoilState(exerciseState);
    const [auth] = useRecoilState(authState);
    const { loadProfile } = useProfile();
    const { sync } = useSyncedHistory();

    useEffect(() => {
        if (auth.loggedIn) {
            loadProfile();
            sync();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.loggedIn]);

    return (
        <div
            onMouseMove={onMove}
            className={classNames(styles.app, {
                [styles.inactive]: exercise?.active && !exercise?.finished,
                [styles.dim]: lightsOff,
            })}
            style={
                isDarkMode
                    ? {
                          background: `url(${starSky})`,
                          backgroundColor: 'black',
                          backgroundSize: 'contain',
                      }
                    : {}
            }
        >
            {children}
            <Link to={'/'}>{logo}</Link>
        </div>
    );
};

const Menu: React.FC<{
    children: JSX.Element[];
    controls: AnimationControls;
}> = ({ children, controls }) => {
    return (
        <motion.div
            variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
            className={styles.menu}
            initial="visible"
            animate={controls}
        >
            {children}
        </motion.div>
    );
};

const Info: React.FC<{
    children: JSX.Element[];
    controls: AnimationControls;
}> = ({ children, controls }) => {
    const [exercise] = useRecoilState(exerciseState);
    return (
        <motion.div
            className={styles.info}
            variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
            initial="visible"
            animate={controls}
        >
            {exercise?.active ? null : children}
        </motion.div>
    );
};

const Root: React.FC = () => {
    const location = useLocation();
    const [exercise] = useRecoilState(exerciseState);
    const [lightsOut, setLightsOut] = useState(false);
    const [lightsOutTimeout, setLightsOutTimeout] = useState<
        ReturnType<typeof setTimeout> | undefined
    >();
    const isMobile = useIsMobile();
    const controls = useAnimation();

    useEffect(() => {
        if (lightsOut && !exercise?.active && !isMobile) {
            controls.start('hidden');
        } else if (
            !lightsOut ||
            exercise?.active ||
            isMobile ||
            location.pathname !== '/'
        ) {
            controls.start('visible');
            setLightsOut(false);
        }
    }, [controls, exercise?.active, lightsOut, isMobile, location.pathname]);

    useEffect(() => {
        clearTimeout(lightsOutTimeout);
        setLightsOut(false);
        setLightsOutTimeout(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // turn off lights when user didn't interact for 10 seconds
    useEffect(() => {
        if (
            location.pathname === '/' &&
            !lightsOut &&
            !lightsOutTimeout &&
            !isMobile
        ) {
            setLightsOutTimeout(
                setTimeout(() => {
                    setLightsOut(true);
                }, 10000)
            );
        }
        return () => clearTimeout(lightsOutTimeout);
    }, [isMobile, lightsOut, lightsOutTimeout, location.pathname]);

    const page = useMemo(() => {
        return (
            <Page
                lightsOff={lightsOut && !exercise?.active}
                logo={<Logo lightsOff={lightsOut} />}
                onMove={() => {
                    controls.start('visible');
                    setLightsOut(false);
                    clearTimeout(lightsOutTimeout);
                    setLightsOutTimeout(undefined);
                }}
            >
                <Airplanes />
                {location.pathname === '/' ? (
                    <Menu controls={controls}>
                        <BreathingExercise />
                        <PausingExercise />
                    </Menu>
                ) : (
                    <Outlet />
                )}
                {location.pathname === '/' ? (
                    <Info controls={controls}>
                        <History />
                        <Result />
                        <Tracker />
                    </Info>
                ) : (
                    <></>
                )}
            </Page>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controls, lightsOut, location.pathname]);

    return page;
};

function App() {
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            let swURL = 'service-worker.js';
            swURL += `?apiURL=${encodeURIComponent(
                process.env.REACT_APP_API_URL as string
            )}`;
            swURL += `&appVersion=${encodeURIComponent(
                process.env.REACT_APP_VERSION as string
            )}`;
            navigator.serviceWorker
                .register(swURL, {
                    scope: '/',
                })
                .then(
                    function (registration) {
                        console.log(
                            'ServiceWorker registration successful with scope: ',
                            registration.scope
                        );
                        if (registration.installing) {
                            console.log('Service worker installing');
                        } else if (registration.waiting) {
                            console.log('Service worker installed');
                        } else if (registration.active) {
                            console.log('Service worker active');
                        }
                    },
                    function (err) {
                        console.log('ServiceWorker registration failed: ', err);
                    }
                );
        }
    }, []);
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            errorElement: (
                <div className={styles.notFound}>
                    <h1>You are looking for something.</h1>
                    <p>Maybe it is right in front of you.</p>
                    <Logo lightsOff={true} />
                    <Airplanes />
                </div>
            ),
            children: [
                {
                    path: '/login',
                    element: <Login />,
                },
                {
                    path: '/register',
                    element: <Register />,
                },
            ],
        },
    ]);
    return (
        <RecoilRoot>
            <RouterProvider router={router} />
        </RecoilRoot>
    );
}

export default App;
