import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import classNames from 'classnames';
import { useIsDarkMode, useIsMobile } from './hooks';
import styles from './Logo.module.css';
import { exerciseState } from './state/exerciseState';

interface LogoProps {
    lightsOff: boolean;
}

const Logo: React.FC<LogoProps> = ({ lightsOff }) => {
    const isMobile = useIsMobile();
    const isDarkMode = useIsDarkMode();
    const getLogo = useCallback(
        () =>
            require(isDarkMode
                ? './assets/easier-place-logo-dark.png'
                : './assets/easier-place-logo.png'),
        [isDarkMode]
    );
    const [exercise] = useRecoilState(exerciseState);

    return (
        <div
            className={classNames(styles.logo, {
                [styles.inactive]:
                    exercise?.active || (exercise?.result && isMobile),
                [styles.dim]: lightsOff,
            })}
        >
            <div style={{ backgroundImage: `url(${getLogo()})` }} />
        </div>
    );
};

export default Logo;
