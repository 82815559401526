import React, { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useSyncedHistory } from '../requests/sync';
import { authState } from '../state/authState';
import { historyState } from '../state/historyState';
import styles from './History.module.css';
import PastExercise from './PastExercise';

const HistoryIcon = () => {
    return (
        <svg
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 5H53M5 29H53M5 53H53"
                stroke="#808080"
                strokeWidth="8.93023"
                strokeLinecap="round"
            />
        </svg>
    );
};

const CloseIcon = () => {
    return (
        <svg
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 5L53 53M5 53L53 5"
                stroke="#808080"
                strokeWidth="8.93023"
                strokeLinecap="round"
            />
        </svg>
    );
};

interface HistoryProps {}

const History: React.FC<HistoryProps> = () => {
    const navigate = useNavigate();
    const listRef = React.useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(false);
    const [history] = useRecoilState(historyState);
    const [auth] = useRecoilState(authState);
    const { sync, error } = useSyncedHistory();

    useEffect(() => {
        if (error === 'Unauthorized') {
            navigate('/login');
            setVisible(false);
        }
    }, [error, navigate]);

    const exercises = useMemo(() => {
        return history.exercises?.map((e, index, all) => {
            const current = new Date().setTime(e.completed);
            const next =
                index !== all.length - 1
                    ? new Date().setTime(all[index + 1]?.completed)
                    : null;
            const currentMoment = moment(current).fromNow();
            const nextMoment = next && moment(next).fromNow();
            let exercise = null;
            if ((index !== 0 && currentMoment === nextMoment) || !next) {
                exercise = (
                    <PastExercise exercise={e} index={e.completed.toString()} />
                );
            } else if (
                currentMoment !== nextMoment ||
                !next ||
                (index === 0 && nextMoment)
            ) {
                exercise = (
                    <PastExercise
                        exercise={e}
                        specialMoment={nextMoment as string}
                        last={index === 0}
                        index={e.completed.toString()}
                    />
                );
            } else {
                exercise = (
                    <PastExercise exercise={e} index={e.completed.toString()} />
                );
            }
            return exercise;
        });
    }, [history.exercises]);

    // Scroll to bottom of list when new exercises are added
    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [visible]);

    const offline = useMemo(() => {
        return !navigator.onLine;
    }, []);

    if (history.exercises && history.exercises.length !== 0) {
        return (
            <div
                id="backdrop"
                className={classNames(styles.history, {
                    [styles.visible]: visible,
                })}
                onClick={(e) => {
                    if ((e.target as HTMLElement).id === 'backdrop')
                        setVisible(false);
                }}
            >
                <div className={styles.exercises} ref={listRef}>
                    {exercises}
                </div>
                <div className={styles.buttons}>
                    {!offline && (
                        <button
                            className={styles.syncButton}
                            onClick={() => {
                                if (!auth.loggedIn) {
                                    navigate('/login');
                                    setVisible(false);
                                } else {
                                    sync();
                                }
                            }}
                        >
                            {history.synced ? 'Synced' : 'Sync'}
                        </button>
                    )}
                    <div
                        className={styles.icon}
                        onClick={() => setVisible(!visible)}
                    >
                        {visible ? <CloseIcon /> : <HistoryIcon />}
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default History;
