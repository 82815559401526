import moment from "moment";
import React, { useEffect, useState } from "react";
import styles from "./Clock.module.css";

interface ClockProps {
    startTime?: number;
}

const Clock: React.FC<ClockProps> = ({ startTime }) => {
    const [current, setCurrent] = useState(moment());

    useEffect(() => {
        const refresh = setInterval(() => {
            console.debug("Refreshing pause clock");
            setCurrent(moment());
        }, 60000);
        return () => {
            clearInterval(refresh);
        };
    }, []);

    return (
        <div className={styles.clock}>
            <div className={styles.current}>{moment().format("hh:mm")}</div>
            <div className={styles.pause}>
                pausing for {current.to(startTime, true)}
            </div>
        </div>
    );
};

export default Clock;
