import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { register } from '../requests/auth';
import styles from './Register.module.css';

const Register: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.register}>
            <form
                className={styles.registerForm}
                onSubmit={(e) => {
                    e.preventDefault();

                    try {
                        // Get values from form
                        const form = e.target as unknown as { value: string }[];
                        const email = form[0].value;
                        const name = form[1].value;
                        const password = form[2].value;
                        const repeatPassword = form[3].value;
                        // Validate
                        if (password !== repeatPassword) {
                            throw new Error('Passwords do not match');
                        }
                        // Send to server
                        register(email, password, name).then(async (res) => {
                            // Handle success
                            if (res.status !== 200) {
                                const body = await res.json();
                                alert('Registering failed: ' + body.error);
                            } else {
                                alert(
                                    'Registered successfully. You can now login.'
                                );
                                navigate('/login');
                            }
                        });
                    } catch (e: any) {
                        // Handle error
                        alert('Registering failed: ' + e.message);
                    }
                }}
            >
                <label htmlFor="email">Email</label>
                <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                />
                <label htmlFor="nickname">Name</label>
                <input
                    id="name"
                    type="text"
                    placeholder="Name"
                    autoComplete="name"
                />
                <label htmlFor="email">Password</label>
                <input
                    id="password"
                    type="password"
                    placeholder="Password"
                    autoComplete="new-password"
                />
                <label htmlFor="email">Repeat password</label>
                <input
                    id="repeat-password"
                    type="password"
                    placeholder="Repeat password"
                />
                <div className={styles.registerButtons}>
                    <Link to={'/login'}>Login</Link>
                    <button type="submit">Register</button>
                </div>
            </form>
        </div>
    );
};

export default Register;
