import { atom } from 'recoil';
import { ExerciseType } from './exerciseState';
import { localStorageEffect } from './effects';

interface Tracker {
    active: boolean;
}

export const trackerState = atom<Tracker & { [key in ExerciseType]: number }>({
    key: 'trackerState', // unique ID (with respect to other atoms/selectors)
    default: { active: false, BREATHING: 0, PAUSING: 0, FOCUSING: 0 },
    effects: [localStorageEffect('trackerState')],
});
