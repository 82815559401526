import { atom } from 'recoil';

import { ExerciseType } from './exerciseState';
import { localStorageEffect } from './effects';

export interface History {
    exercises: { type: ExerciseType; result: number; completed: number }[];
    synced: boolean;
}

export const historyState = atom<History>({
    key: 'historyState', // unique ID (with respect to other atoms/selectors)
    default: { exercises: [], synced: false },
    effects: [localStorageEffect('historyState')],
});
