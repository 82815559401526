import { useRecoilState } from 'recoil';

import { useCallback, useEffect, useState } from 'react';
import { profileState } from '../state/profileState';
import { useFetchApiResource } from './auth';

export const useProfile = () => {
    const [profile, setProfile] = useRecoilState(profileState);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const fetchProfile = useFetchApiResource(
        '/profile',
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        },
        setProfile,
        setError
    );

    const loadProfile = useCallback(() => {
        setLoading(true);
        fetchProfile().then(() => setLoading(false));
    }, [fetchProfile]);

    useEffect(() => {
        if (error) {
            setProfile({ id: undefined });
        }
    }, [error, setProfile]);

    return { profile, loading, loadProfile, error };
};
