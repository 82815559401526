import { atom } from 'recoil';
import { localStorageEffect } from './effects';

export interface Profile {
    id?: string;
    email?: string;
    name?: string;
}

export const profileState = atom<Profile>({
    key: 'profileState', // unique ID (with respect to other atoms/selectors)
    default: { id: undefined },
    effects: [localStorageEffect('profileState')],
});
