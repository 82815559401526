import { useEffect, useMemo, useState } from 'react';

function useMediaQuery(query: string) {
    const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
    const [match, setMatch] = useState(mediaQuery.matches);

    useEffect(() => {
        const onChange = () => setMatch(mediaQuery.matches);
        mediaQuery.addEventListener('change', onChange);

        return () => mediaQuery.removeEventListener('change', onChange);
    }, [mediaQuery]);

    return match;
}

export const useIsDarkMode = () => {
    const [isDark, setIsDark] = useState<boolean | undefined>(undefined);

    const matches = useMediaQuery('(prefers-color-scheme: dark)');
    useEffect(() => {
        setIsDark(matches);
    }, [matches]);

    return !!isDark;
};

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);

    const matches = useMediaQuery('screen and (max-width: 1024px)');
    useEffect(() => {
        setIsMobile(matches);
    }, [matches]);

    return !!isMobile;
};
