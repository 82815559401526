import { AtomEffect } from 'recoil';

function localStorageEffect<T>(key: string) {
    const effect: AtomEffect<T> = ({ setSelf, onSet }) => {
        const savedValue = localStorage.getItem(key);
        if (savedValue != null) {
            setSelf(JSON.parse(savedValue));
        }

        onSet((newValue, _, isReset) => {
            isReset
                ? localStorage.removeItem(key)
                : localStorage.setItem(key, JSON.stringify(newValue));
        });
    };
    return effect;
}

export { localStorageEffect };
