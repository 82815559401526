import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useRecoilState } from 'recoil';

import styles from './MessageDisplay.module.css';

import { exerciseState, ExerciseType } from '../state/exerciseState';
import { useProfile } from '../requests/profile';
import { authState } from '../state/authState';
import { useIsMobile } from '../hooks';

function capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export const formatTime = (milliseconds: number) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

    const duration = moment
        .duration(
            [
                hours.toString().padStart(2, '0'),
                minutes.toString().padStart(2, '0'),
                seconds.toString().padStart(2, '0'),
            ].join(':')
        )
        .humanize();

    return capitalizeFirstLetter(duration);
};

interface ResultProps {}

const MessageDisplay: React.FC<ResultProps> = () => {
    const [auth] = useRecoilState(authState);
    const [exercise] = useRecoilState(exerciseState);
    const { profile } = useProfile();
    const isMobile = useIsMobile();

    const exerciseResult = useMemo(() => {
        if (exercise?.result) {
            switch (exercise.type) {
                case ExerciseType.breathing:
                    return exercise.result > 1
                        ? `${exercise.result} breaths`
                        : '1 breath';
                case ExerciseType.pausing:
                    return `${formatTime(exercise.result)}`;
            }
        }
    }, [exercise]);

    const greeting = useMemo(() => {
        if (!auth.loggedIn) return '';
        const hour = new Date().getHours();
        let greeting = 'Good evening';
        if (hour < 12) greeting = 'Good morning';
        if (hour < 18) greeting = 'Good afternoon';
        return `${greeting} ${profile.name}.`;
    }, [auth.loggedIn, profile.name]);

    const offline = useMemo(() => {
        return navigator.onLine ? null : 'Offline mode.';
    }, []);

    return (
        <div
            className={classNames(styles.completed, {
                [styles.active]: (exercise?.finished || greeting) && !isMobile,
            })}
        >
            {exerciseResult || offline || greeting}
        </div>
    );
};

export default MessageDisplay;
