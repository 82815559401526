import { useRecoilState } from 'recoil';

import { authState } from '../state/authState';

export const login = (email: string, password: string) => {
    return fetch(process.env.REACT_APP_API_URL + '/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
        body: JSON.stringify({ email, password }),
    });
};

export const register = (email: string, password: string, name: string) => {
    return fetch(process.env.REACT_APP_API_URL + '/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, name }),
    });
};

export const useFetchApiResource = <T>(
    resource: string,
    options: RequestInit,
    onSuccess: (resource: T) => void,
    onError: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_auth, setAuth] = useRecoilState(authState);

    return async () => {
        const res = await fetch(process.env.REACT_APP_API_URL + resource, {
            ...options,
        }).catch((e) => {
            onError(e.message);
            setAuth({ loggedIn: false, email: undefined });
            return { status: 500, json: async () => ({ error: e.message }) };
        });
        if (res.status === 200) {
            const body = await res.json();
            onSuccess(body);
        } else if (res.status === 401) {
            setAuth({ loggedIn: false, email: undefined });
            const body = await res.json();
            onError(body.error);
        } else {
            const body = await res.json();
            onError(body.error);
        }
    };
};
