import { useEffect, useMemo, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import classNames from 'classnames';
import moment from 'moment';

import { formatTime } from './MessageDisplay';
import styles from './PastExercise.module.css';

import { ExerciseType } from '../state/exerciseState';

const displayResult = (type: ExerciseType, result: number) => {
    switch (type) {
        case ExerciseType.breathing:
            return `for ${result} ${result > 1 ? 'breaths' : 'breath'}`;
        case ExerciseType.pausing:
            return `for ${formatTime(result).toLowerCase()}`;
    }
};

const PastExercise: React.FC<{
    exercise: { type: ExerciseType; result: number; completed: number };
    specialMoment?: string;
    last?: boolean;
    index?: string;
}> = ({ exercise, specialMoment, last, index }) => {
    const ref = useRef(null);
    const isInview = useInView(ref, { amount: 1 });
    const controls = useAnimation();

    useEffect(() => {
        if (isInview) {
            controls.start('visible');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInview]);

    const completed = useMemo(() => {
        return new Date().setTime(exercise.completed);
    }, [exercise.completed]);

    const render = (children: JSX.Element | JSX.Element[], blank?: boolean) => (
        <motion.div
            ref={ref}
            variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
            }}
            transition={{
                type: 'spring',
                stiffness: 100,
                damping: 10,
            }}
            initial="hidden"
            animate={controls}
            className={classNames({ [styles.exercise]: !blank })}
            // title={moment(completed).format('YYYY/DD/MM hh:mm')}
            title={moment(completed).fromNow()}
        >
            {children}
        </motion.div>
    );

    const renderedExercise = render([
        <div className={styles.header} key={`header-${index}`}>
            <div className={styles.title}>{exercise.type}</div>
        </div>,
        <div className={styles.description} key={`description-${index}`}>
            {displayResult(exercise.type, exercise.result)}
        </div>,
    ]);

    if (specialMoment) {
        return (
            <div key={index} className={styles.container}>
                {last
                    ? [
                          render(
                              <div
                                  className={styles.momentCompleted}
                                  key={exercise.completed}
                              >
                                  {specialMoment}
                              </div>,
                              true
                          ),
                          renderedExercise,
                      ]
                    : [
                          renderedExercise,
                          render(
                              <div
                                  className={styles.momentCompleted}
                                  key={exercise.completed}
                              >
                                  {specialMoment}
                              </div>,
                              true
                          ),
                      ]}
            </div>
        );
    } else {
        return renderedExercise;
    }
};

export default PastExercise;
