import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { exerciseState, ExerciseType } from '../../state/exerciseState'
import styles from './BreathingExercise.module.css'
import Exercise from './Exercise'

interface BreathingExerciseProps {}

const BreathingExercise: React.FC<BreathingExerciseProps> = () => {
    const [exercise] = useRecoilState(exerciseState)
    const [instruction, setInstruction] = useState<'IN' | 'OUT' | undefined>()
    const [breaths, setBreaths] = useState<number>(0)
    const [breatheTimer, setBreatheTimer] = useState<
        ReturnType<typeof setTimeout> | undefined
    >()

    useEffect(() => {
        if (
            exercise?.active &&
            exercise.type === ExerciseType.breathing &&
            !exercise?.countdown
        ) {
            if (instruction === 'IN') {
                setBreatheTimer(
                    setTimeout(() => {
                        setInstruction('OUT')
                    }, 8000)
                )
            } else if (instruction === 'OUT') {
                setBreatheTimer(
                    setTimeout(() => {
                        setBreaths(breaths + 1)
                        setInstruction('IN')
                    }, 9000)
                )
            }
        } else {
            setInstruction(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instruction])

    return (
        <Exercise
            title="Breathe"
            type={ExerciseType.breathing}
            countdownCallback={() => {
                setInstruction('IN')
            }}
            onStart={() => {
                clearTimeout(breatheTimer)
                setInstruction(undefined)
                setBreaths(0)
            }}
            onFinish={() => breaths}
            animation={
                <div
                    className={classNames(styles.animation, {
                        [styles.active]: instruction,
                    })}
                    style={{
                        animation:
                            instruction === 'IN'
                                ? styles.breatheIn
                                : styles.breatheOut,
                        animationDuration: instruction === 'IN' ? '8s' : '9s',
                        height: instruction === 'IN' ? '100%' : '64px',
                    }}
                >
                    <div className={styles.instruction}>{instruction}</div>
                </div>
            }
        />
    )
}

export default BreathingExercise
