import { atom } from 'recoil';

export enum ExerciseType {
    'breathing' = 'BREATHING',
    'pausing' = 'PAUSING',
    'focusing' = 'FOCUSING',
}

interface Exercise {
    active: boolean;
    countdown: boolean;
    finished: boolean;
    type: ExerciseType;
    result?: number;
}

export const exerciseState = atom<Exercise>({
    key: 'exerciseState', // unique ID (with respect to other atoms/selectors)
    default: undefined,
});
